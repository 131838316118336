<template>
    <multiselect :disabled="this.disabled" 
        :readonly="this.disabled" 
        :allowEmpty="this.allowEmpty" 
        @search-change="handleSearch" 
        :label="this.label ? this.label : 'name'"
        :track-by="this.trackBy ? this.trackBy : 'name'" 
        :loading="this.loading"
        :select-label="''"
        :deselect-label="''"
        :options="(this.optionsData&&this.optionsData.length > 0) ? this.optionsData : (this.options != undefined ? this.options : [])"
        v-model="selected" 
        class="multiselect-grrev"
        :placeholder="placeholder ? placeholder : `type to search`">
        <!-- Custom option slot -->
        <template #option="{ option }">
            <div :style="{ whiteSpace: 'pre-line' }">
                {{ option.custom }}
            </div>
        </template>
        <!-- Custom selected item slot -->
        <template #selected-item="{ item }">
            <div :style="{ whiteSpace: 'pre-line' }">
                {{ item.custom }}
            </div>
        </template>
    </multiselect>        
</template>
<script>

export default {
    components: {
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String
        },
        label: {
            type: String,
        },
        baseUrl: {
            type: String,
        },
        allowEmpty:{
            type:Boolean,
            default:true,
        },
        defaultFirst:{
            type:Boolean,
            default:false,
        },
        trackBy: {
            type: String,
        },
        value: {
            type: Object,
        },
        options: {
            required: false
        }
    },
    data() {
        return {
            typedValue: "",
            timeOuts: null,
            optionsData: [],
            loading: false,
            selected: this.value ? this.value : {}
        };
    },
    methods: {        
        customSku({ code_gr, external_code_gr, code_po, external_code_po }) {
            const result =  `GR Code : ${code_gr} \nGR Code External : ${external_code_gr || ''} \nPO Code : ${code_po} \nPO Code External : ${external_code_po || ''}`;
            console.log('Generated HTML:', result);
            return result
        },
        handleSearch(v) {
            this.typedValue = v;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.get, 400);
        },
        get() {
            this.loading = true;
            this.$http
                .get(this.baseUrl, {
                    params: {
                        order: "id",
                        sort: "asc",
                        query: this.typedValue,
                    }
                })
                .then(resp => {
                    if (resp.code == 200) {
                        if (this.label == "custom") {
                            this.optionsData = resp.data.records.map(record => ({
                                ...record,
                                custom: this.customSku(record)
                            }));
                        }else{
                            this.optionsData = resp.data.records
                        }
                        // console.log("isfirst",this.defaultFirst);
                        if (this.defaultFirst) {
                            this.selected=resp.data.records[0]
                            this.$emit("selected", resp.data.records[0]);
                        }else if (!this.value && resp.data.default) {
                            this.selected = resp.data.default;
                            this.$emit("selected", resp.data.default);
                        }
                    } else {
                        this.optionsData = [];
                    }
                    // console.log(resp);
                });
            this.loading = false;
        }

    },
    watch: {
        selected(v) {
            console.log('Selected:', v);
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            console.log('Value Changed:', v);
            if (v) {
                this.selected = v
            }
        }
    },
    mounted() {
        if (this.baseUrl != "" && this.baseUrl != undefined) {
            this.get();
        } else if (this.options.length > 0) {
            this.optionsData = this.options            
        }
    }
};
</script>
<style>
.multiselect--above .multiselect__content-wrapper{
    bottom: auto !important;
    top: 100% !important;
}
.multiselect-grrev .multiselect__single{
    white-space: pre-line;
}
</style>
